<template>

<router-view/>

</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 
	// import  lightBox from "@/components/LightBox.vue";

	export default {
		name: 'GartengestaltungParent',
		components: {

		},
		mounted(){


		}, // end mounted 
		data: 
		function(){
			return {

			}
		}, // end data
		methods: {




		} // end methods
	} // end export default

</script>



<style scoped lang="scss">



</style>
